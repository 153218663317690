<template>
    <div class="metope-list">
		<!-- page-position="墙面定制/装饰画" -->
        <mall-banner
			page-position="墙面定制/装饰画"
            router-name="前往图文制作"
            router-path="/media">
            <!-- 搜索框 -->
            <template #search>
                <search-component
                    :search-value="search"
                    :selected-value="selectedType"
                    :select-options="filterType"
                    @handleSearch="arg => handleFun(arg.search,arg.type,selectedStyle,selectedSort)">
                </search-component>
            </template>
        </mall-banner>
        <div class="container">
            <!-- filter -->
            <list-filter
                :sort-list="testSort"
                :count-num="listCount"
                :selected-sort="selectedSort"
                @handleSort="arg => handleFun(search,selectedType,selectedStyle,arg)">
                <template #filter>
                    <filter-list
                        filter-title="分类："
                        :filter-array="filterType"
                        :select-value="selectedType"
                        @handleClick="arg => handleFun(search,arg,selectedStyle,selectedSort)">
                    </filter-list>
                    <filter-list
                        v-if="filterStyle.length>0"
                        filter-title="风格："
                        :filter-array="filterStyle"
                        :select-value="selectedStyle"
                        @handleClick="arg => handleFun(search,selectedType,arg,selectedSort)">
                    </filter-list>
                </template>
            </list-filter>
            <!-- list -->
            <ul class="metope-list-item clearfix">
                <li v-for="(item, index) in metopeList"
                    :key="index">
                    <goods-item
                        router-path="metopeDetail"
                        :goods-item="item"
						:page-data = "datum"
						:selected-style= "filterStyle[selectedStyle]"
						>
                    </goods-item>
                </li>
            </ul>
            <!-- page -->
            <div class="metope-list-page">
                <el-pagination
                    layout="prev, pager, next, jumper"
                    :current-page="page.current_page"
                    :page-count="page.page_count"
                    @current-change="arg => handleFun(search,selectedType,selectedStyle,selectedSort,arg)">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapMutations, mapActions } from "vuex";

    export default {
        data() {
            let search = sessionStorage.getItem('search') || '',
                selectedType = sessionStorage.getItem('type') || '0',
                selectedStyle = sessionStorage.getItem('style') || '0',
                selectedSort = sessionStorage.getItem('sort') || '';
            return {
                search,
                selectedType,
                selectedStyle,
                selectedSort,
                testSort: [
                    {
                        value: 1,
                        label: "热门推荐"
                    },
                    {
                        value: 2,
                        label: "最新上传"
                    },
                    {
                        value: 3,
                        label: "使用最多"
                    }
                ],
				datum:{}
            }
        },
        computed: mapState({
            metopeList: state => state.metope.metopeList,
            listCount: state => state.metope.listCount,
            page: state => state.metope.page,
            filterStyle: state => state.metope.filterStyle,
            filterType: state => state.metope.filterType
        }),
        components: {
            searchComponent: () => import("@/components/imageMall/searchComponent"),
            mallBanner: () => import("@/components/makingMall/mallBanner"),
            listFilter: () => import("@/components/imageMall/listFilter"),
            filterList: () => import("@/components/equipment/filterList"),
            goodsItem: () => import("@/components/makingMall/goodsItem")
        },
        methods: {
            ...mapMutations([
                'setMetopeFilters',
                'setMetopeList',
                'setMetopePageList'
            ]),
            ...mapActions([
                'getMetopeFilters',
                'getMetopeList'
            ]),
            handleFun(search, type, style, sort, page){
				
                sessionStorage.setItem('search', search);
                sessionStorage.setItem('type', type);
                sessionStorage.setItem('style', style);
                sessionStorage.setItem('sort', sort);
				sessionStorage.setItem('page', page);
                this.search = search || '';
                this.selectedType = type || '全部';
                this.selectedStyle = style;
                this.selectedSort = sort;
                this.getMetopeFilters(type);
				this.datum = {
					search,
					type,
					style,
					sort,
					page
				}
				let typer = type == '全部'?'0':type;
                this.getMetopeList({
                    search,
                    type:typer,
                    style,
                    sort,
                    page
                })
            }
        },
        beforeRouteEnter (to, from, next) {
            next(vm => {
                // vm.handleFun(vm.search, vm.selectedType, vm.selectedStyle, vm.selectedSort, vm.page.current_page);
				vm.handleFun(to.query.search, to.query.type, to.query.style, to.query.sort, to.query.page);
				
				// console.log('查看',to.query.search, to.query.type, to.query.style, to.query.sort, to.query.page)
            })
			// console.log(to.query)
			
			
		
			
        },
        beforeRouteLeave (to, from, next) {
            sessionStorage.removeItem('search');
            sessionStorage.removeItem('type');
            sessionStorage.removeItem('style');
            sessionStorage.removeItem('sort');
            this.setMetopeFilters({});
            this.setMetopeList([]);
            this.setMetopePageList({});
            next();
        }
		
    }
</script>

<style scoped>
.metope-list{
    --color: #1f3eaa;
    margin-bottom: 50px;
}
/* list */
.metope-list-item > li{
    float: left;
    width: 25%;
    padding: 10px;
    margin-top: 50px;
}

/* page */
.metope-list-page{
    margin: 50px 0;
    text-align: center;
}
</style>